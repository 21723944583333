<template>
  <div>
     <NavBar
  title="体检数据"
  left-arrow
  @click-left="$router.go(-1)"
/>
  <BaseInfo :baseInfo="$store.state.user.baseInfo" />
  <collapse v-model="activeName"  @change="changeCollapse" >
    <loading size="24px" v-if="isloading" >加载中...</loading>
    <collapse-item  :title="section.title" :name="section.id" v-for="(section,index) in sections " :key="index">
            <cell  center    v-for="(item,index) in section.items" :key="index" >
              <template >
                  <span class="custom-value" >{{item.value.value}}</span> 
                  <span v-if="demo.unit" class="custom-unit">{{item.value.units?item.value.units:item.units}}</span>
              </template>
              <template #title>
                 <tag type="danger" v-if="item.value.warn==3" >异常</tag>
                 <tag type="danger" v-if="item.value.warn==2" >偏高</tag>
                 <tag type="danger" v-if="item.value.warn==1" >偏低</tag>
                 <span class="custom-title" >{{item.title}}</span> 
              </template>
            </cell>
    </collapse-item>
 </collapse>
 <empty v-if="nodata" description="查无数据" />
 </div>
</template>

<script>

import {  Collapse, CollapseItem,Cell,Tag,Loading,Empty } from 'vant';
import {getSections} from '@/api/tjdata/section';
export default {
  name: 'Tjdata',
  components: {
      Collapse,CollapseItem,Cell,Tag,Loading  ,Empty
  },
  data(){
    return {
      activeName: [],
      demo:{title:"体重",value:"123",unit:"cm",warn:1},
      isloading:true,
      sections:[],
      nodata:false
    };
  },
  methods:{
      changeCollapse(activeNames){
        console.info(activeNames);
        activeNames.forEach(name=>{
            console.info(name);
        });
      }
  }
,
   created() {
    /*初始化section*/
    getSections({rid:this.$route.query.rid}).then((response) => {
          this.isloading=false;
          this.sections=response.data;   
          if(this.sections.length==0){
              this.nodata=true;
          }
    })
  },
  computed:{
      
  }
}
</script>

<style scoped>

.custom-title {
    margin-right: 4px;
    vertical-align: middle;
  }

  .search-icon {
    font-size: 16px;
    line-height: inherit;
  }

  .custom-value{
    
     margin-left: 0em;
  }
  .custom-unit{
      margin-left: .5em;
  }
</style>
