import request from '@/utils/request'


// 查询接口列表
export function getSections(param) {
    return request({
      url: '/baseinfo/sections',
      method: 'get',
      params:param
    })
  }
